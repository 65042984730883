import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from './public.routes'
import adminRoutes from './admin.routes'
import dashboardRoutes from './dashboard.routes'
import artistRoutes from './artist.routes'
import venueRoutes from './venue.routes'
import miscRoutes from './misc.routes'

Vue.use(VueRouter)

const routes = [
    ...publicRoutes,
    ...adminRoutes,
    ...dashboardRoutes,
    ...artistRoutes,
    ...venueRoutes,
    ...miscRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.name === from.name && to.hash) {
        return {
            "selector": to.hash
        }
    }

    next()
})

export default router
