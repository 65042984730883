
export default [
    {
        path: '/artist',
        name: 'artist.index',
        component: () => import('@/views/artist/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/profile',
        name: 'artist.profile',
        component: () => import('@/views/artist/Profile.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/tracks',
        name: 'artist.tracks.index',
        component: () => import('@/views/artist/tracks/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/tracks/create',
        name: 'artist.tracks.create',
        component: () => import('@/views/artist/tracks/Form.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/tracks/:id',
        name: 'artist.tracks.show',
        component: () => import('@/views/artist/tracks/Form.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/gigs',
        name: 'artist.gigs.index',
        component: () => import('@/views/artist/gigs/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/gigs/:id',
        name: 'artist.gigs.show',
        component: () => import('@/views/artist/gigs/Show.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/gigs/:id/edit',
        name: 'artist.gigs.edit',
        component: () => import('@/views/artist/gigs/Edit.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/videos',
        name: 'artist.videos.index',
        component: () => import('@/views/artist/videos/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/videos/create',
        name: 'artist.videos.create',
        component: () => import('@/views/artist/videos/Form.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/videos/:id',
        name: 'artist.videos.show',
        component: () => import('@/views/artist/videos/Form.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/equipment/sync',
        name: 'artist.equipment.sync',
        component: () => import('@/views/artist/equipment/Sync.vue'),
        meta: {auth: true}
    },
    {
        path: '/artist/instruments/sync',
        name: 'artist.instruments.sync',
        component: () => import('@/views/artist/instruments/Sync.vue'),
        meta: {auth: true}
    },
]
