import venue from './venue'
import artist from './artist'

const policies = {
    venue,
    artist
}

const state = {

};

const getters = {
    can: () => (ability, resource) => {
        const policy = policies[resource]

        if (!policy) {
            return false
        }

        return policy[ability] ? policy[ability]() : false
    }
};

const mutations = {

};

const actions = {

};

const modules = {

}

export default {
    namespaced: true,
    state, getters, mutations, actions, modules
}
