import Vue from "vue";

export function getUser() {
    return Vue.auth.user()
}

export function getPermissions() {
    const user = getUser();

    return user ? user['permissions'] : [];
}

export function boolPermission(perm) {
    return !!getPermissions()[perm]
}

export function inf(perm) {
    let c = getPermissions()[perm]

    if (!c) {
        return false
    }

    if (c === 'INF') {
        c = Infinity
    }

    return c > 1
}
