<template>
    <v-app>
        <router-view/>
        <saas-snackbar />
    </v-app>
</template>

<script>
    import SaasSnackbar from "./components/SaasSnackbar";

    export default {
        name: 'App',
        mounted () {
            this.$root.mini = false;
            this.$root.$on('toggleDrawer', () => {
                this.$root.mini = ! this.$root.mini;
            })
        },
        computed: {
            layout() {
                return `${(this.$route.meta.layout || 'empty')}-layout`
            },
        },
        components: {
            SaasSnackbar,
        },

        data: () => ({
            //
        }),
    };
</script>
<style type="text/css">
    #nprogress .bar {
        height: 4px !important;
    }
</style>
