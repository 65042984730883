export default [
    {
        path: '/venue',
        name: 'venue.index',
        component: () => import('@/views/venue/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/profile',
        name: 'venue.profile',
        component: () => import('@/views/venue/Profile.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/gigs',
        name: 'venue.gigs.index',
        component: () => import('@/views/venue/gigs/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/gigs/:id',
        name: 'venue.gigs.show',
        component: () => import('@/views/venue/gigs/Show.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/menus',
        name: 'venue.menus.index',
        component: () => import('@/views/venue/menus/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/menus/create',
        name: 'venue.menus.create',
        component: () => import('@/views/venue/menus/Form.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/venue-features/sync',
        name: 'venue.venue-features.sync',
        component: () => import('@/views/venue/venue-features/Sync.vue'),
        meta: {auth: true}
    },
    {
        path: '/venue/food-types/sync',
        name: 'venue.food-types.sync',
        component: () => import('@/views/venue/food-types/Sync.vue'),
        meta: {auth: true}
    },
]
