import Echo from 'laravel-echo';
require( './axios')

require( './vue-api-query')

window.Pusher = require('pusher-js');
window.Pusher.logToConsole = true;

import Vue from 'vue';
import VuetifyDatetimePicker from  'vuetify-datetime-picker'
Vue.use(VuetifyDatetimePicker);

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    forceTLS:  process.env.VUE_APP_PUSHER_USE_TLS,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                window.axios.post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
});
