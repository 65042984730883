export  default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/layouts/GjsPageLayout.vue'),
        meta: {pageSlug: 'homepage'}
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/layouts/GjsPageLayout.vue'),
        meta: {pageSlug: 'about'}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {auth: false}
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('../views/SignUp.vue'),
        meta: {auth: false}
    },
    {
        path: '/verify-email/:id?/:hash?',
        name: 'verify-email',
        component: () => import('../views/VerifyEmail'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {auth: false}
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {auth: false}
    },
    {
        path: '/403',
        name: '403',
        component: () => import('../views/static/403.vue'),
        meta: { }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/layouts/GjsPageLayout.vue'),
        meta: {pageSlug: 'privacy-policy'}
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@/layouts/GjsPageLayout.vue'),
        meta: {pageSlug: 'terms-of-service'}
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        component: () => import('@/layouts/GjsPageLayout.vue'),
        meta: {pageSlug: 'how-it-works'}
    },
    {
        path: '/artists',
        name: 'artists-index',
        component: () => import('../views/ArtistsIndex.vue'),
        meta: { }
    },
    {
        path: '/artists/:slug',
        name: 'artist.public',
        component: () => import('../views/Artist.vue'),
        meta: { }
    },
    {
        path: '/venues',
        name: 'venues-index',
        component: () => import('../views/VenuesIndex.vue'),
        meta: { }
    },
    {
        path: '/venues/:slug',
        name: 'venue.public',
        component: () => import('../views/Venue.vue'),
        meta: { }
    },
    {
        path: '/events',
        name: 'events.index',
        component: () => import('../views/Events/Index.vue'),
        meta: { }
    },
    {
        path: '/menus/:slug',
        name: 'menus.public',
        component: () => import('../views/Menu.vue'),
        meta: { }
    },
    {
        path: '/artist-landing',
        name: 'artist-landing',
        component: () => import('../views/ArtistLanding.vue'),
        meta: { }
    },
    {
        path: '/venue-landing',
        name: 'venue-landing',
        component: () => import('../views/VenueLanding.vue'),
        meta: { }
    },
    {
        path: '/fans-landing',
        name: 'fans-landing',
        component: () => import('../views/FansLanding.vue'),
        meta: { }
    },
]
