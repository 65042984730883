import Vue from "vue";

Vue.directive('can', {
    async inserted(el, binding, vnode) {
        await check(el, binding, vnode, true)
    }
})

Vue.directive('cannot', {
    async inserted(el, binding, vnode) {
        await check(el, binding, vnode, false)
    }
})

Vue.mixin({
    methods: {
        can(abilities, resource) {
            for (const ability of this.$_.castArray(abilities)) {
                if (!this.$store.getters['policies/can'](ability, resource)) {
                    return false;
                }
            }

            return true;
        }
    }
})


const check = async function (el, binding, vnode, bool) {
    const empty = document.createElement('div')
    empty.hidden = true
    el.parentNode.replaceChild(empty, el)

    const resource = binding.value['resource']
    const ability = binding.value['ability']

    if (!!vnode.context.can(ability, resource) === bool) {
        empty.parentNode.replaceChild(el, empty)
    }
}
