import {boolPermission} from './helpers'


export default {
    haveVideos() {
        return boolPermission('videos')
    },
    haveManyTracks() {
        return boolPermission('unlimited_tracks')
    },
    haveWebsiteUrl() {
        return boolPermission('website_url')
    },
    haveMerchUrl() {
        return boolPermission('merch_url')
    },
    haveProfilePhoto() {
        return boolPermission('profile_photo')
    },
}
