import Vue from 'vue';

Vue.mixin({
    methods: {
        $loginRedirectRoute: function (roles) {
            let roleName;

            if (roles.includes('admin')) {
                roleName = 'admin';
            }

            if (roles.includes('artist')) {
                roleName = 'artist';
            }

            if (roles.includes('fan')) {
                roleName = 'fan';
            }

            if (roles.includes('venue')) {
                roleName = 'venue';
            }

            return this.$auth.options.loginData.redirect[roleName]
        }
    }
})
