import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#123361',
                secondary: '#424242',
                lightGrey: '#e6e6e6',
                darkGrayishBlue: '#777c80',
                veryDarkBlue: '#173768',
                vividViolet: '#7c0bef',
                veryDarkPink: '#70093c',
                strongRed: '#d11212',
                accent: '#82B1FF',
                error: '#af1447',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                artistPrimary: '#4801a5',
                venuePrimary: '#ce790a',
                fanPrimary: '#ad2280',
                anchor: '#124dc5',
            },
            dark: {
                primary: '#fd5312',
                secondary: '#424242',
                lightGrey: '#f2f2f2',
                accent: '#fb9f1e',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
});
