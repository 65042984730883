
export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans',
        name: 'admin.price-plans.index',
        component: () => import('../views/admin/price-plans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/create',
        name: 'admin.price-plans.create',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/:id',
        name: 'admin.price-plans.edit',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.show',
        component: () => import('../views/admin/users/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tracks',
        name: 'admin.tracks.index',
        component: () => import('../views/admin/tracks/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tracks/:id',
        name: 'admin.tracks.show',
        component: () => import('../views/admin/tracks/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/artists',
        name: 'admin.artists.index',
        component: () => import('../views/admin/artists/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/artists/:id',
        name: 'admin.artists.show',
        component: () => import('../views/admin/artists/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/fans',
        name: 'admin.fans.index',
        component: () => import('../views/admin/fans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/fans/:id',
        name: 'admin.fans.show',
        component: () => import('../views/admin/fans/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/genres',
        name: 'admin.genres.index',
        component: () => import('../views/admin/genres/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/genres/create',
        name: 'admin.genres.create',
        component: () => import('../views/admin/genres/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/genres/:id',
        name: 'admin.genres.show',
        component: () => import('../views/admin/genres/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/food-types',
        name: 'admin.food-types.index',
        component: () => import('../views/admin/food-types/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/food-types/create',
        name: 'admin.food-types.create',
        component: () => import('../views/admin/food-types/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/food-types/:id',
        name: 'admin.food-types.show',
        component: () => import('../views/admin/food-types/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/gigs',
        name: 'admin.gigs.index',
        component: () => import('../views/admin/gigs/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/gigs/:id',
        name: 'admin.gigs.show',
        component: () => import('../views/admin/gigs/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/blocks',
        name: 'admin.blocks.index',
        component: () => import('../views/admin/blocks/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/blocks/:id',
        name: 'admin.blocks.show',
        component: () => import('../views/admin/blocks/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/email-templates',
        name: 'admin.email-templates.index',
        component: () => import('../views/admin/email-templates/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/email-templates/:id',
        name: 'admin.email-templates.show',
        component: () => import('../views/admin/email-templates/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/pages',
        name: 'admin.pages.index',
        component: () => import('../views/admin/pages/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/help',
        name: 'admin.help.index',
        component: () => import('../views/admin/help/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/pages/:id',
        name: 'admin.pages.show',
        component: () => import('../views/admin/pages/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/videos',
        name: 'admin.videos.index',
        component: () => import('../views/admin/videos/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/videos/:id',
        name: 'admin.videos.show',
        component: () => import('../views/admin/videos/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venues',
        name: 'admin.venues.index',
        component: () => import('../views/admin/venues/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venues/:id',
        name: 'admin.venues.show',
        component: () => import('../views/admin/venues/Show.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venue-features',
        name: 'admin.venue-features.index',
        component: () => import('../views/admin/venue-features/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venue-features/create',
        name: 'admin.venue-features.create',
        component: () => import('../views/admin/venue-features/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/artist-submissions',
        name: 'admin.artist-submissions.index',
        component: () => import('@/views/admin/artist-submissions/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venue-submissions',
        name: 'admin.venue-submissions.index',
        component: () => import('../views/admin/venue-submissions/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/venue-claim-requests',
        name: 'admin.venue-claim-requests.index',
        component: () => import('../views/admin/venue-claim-requests/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/coupons',
        name: 'admin.coupons.index',
        component: () => import('@/views/admin/coupons/Index'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/coupons/create',
        name: 'admin.coupons.create',
        component: () => import('@/views/admin/coupons/Form'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/coupons/:id',
        name: 'admin.coupons.show',
        component: () => import('@/views/admin/coupons/Form'),
        meta: {
            auth: 'admin'
        }
    },
];
