import {boolPermission as bool, getPermissions, inf} from './helpers'

export default {
    haveWebsiteUrl() {
        return bool('website_url')
    },
    haveCustomCalendar() {
        return bool('custom_calendar')
    },
    haveMenu() {
        return bool('menu')
    },
    haveFullMenu() {
        return getPermissions()['menu'] === 'full'
    },
    haveImage() {
        return bool('max_images')
    },
    haveMultipleImages() {
        return inf('max_images')
    },
}
